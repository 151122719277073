import React, { useMemo } from 'react'
import { Image, StyleSheet, View } from 'react-native'

import videoPlayImage from '~/assets/images/video_play.png'
import ProportionalImage from '~/components/common/atoms/ProportionalImage'
import color from '~/constants/common/color'

type Props = {
  video: boolean
  small: boolean
  uri: string
  ignoreCache: boolean
  onError?: () => void
  onLoad?: () => void
}

const wrapURI = (uri: string) =>
  `${uri}${uri.includes('?') ? '&' : '?'}_t=${new Date().getTime()}`

const MediaThumbnail: React.FC<Props> = ({
  video,
  small,
  uri,
  ignoreCache,
  onLoad,
  onError,
}: Props) => {
  let image: React.ReactElement
  uri = useMemo(() => (ignoreCache ? wrapURI(uri) : uri), [uri, ignoreCache])
  if (small) {
    image = (
      <Image
        testID="media-thumbnail-image"
        source={{ uri: uri }}
        style={styles.image}
        onLoad={onLoad}
        onError={onError}
      />
    )
  } else {
    image = (
      <ProportionalImage
        style={styles.container}
        uri={uri}
        stretch="vertical"
        onLoad={onLoad}
        onError={onError}
      />
    )
  }
  if (video) {
    const iconStyle = small
      ? styles.smallVideoPlayIcon
      : styles.normalVideoPlayIcon
    return (
      <View style={styles.container}>
        {image}
        <View style={styles.mask}>
          <Image
            testID="media-thumbnail-video-play-icon"
            source={videoPlayImage}
            style={iconStyle}
          />
        </View>
      </View>
    )
  } else {
    return image
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
  },
  image: {
    flex: 1,
    maxHeight: 600,
  },
  mask: {
    alignItems: 'center',
    backgroundColor: color.mask,
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  normalVideoPlayIcon: {
    height: 53,
    width: 46,
  },
  smallVideoPlayIcon: {
    height: 30,
    width: 26,
  },
})

export default MediaThumbnail
