import { useNavigation } from '@react-navigation/native'
import i18n from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, Image, Platform, StyleSheet, View } from 'react-native'

import careerIcon from '~/assets/images/icons/career.png'
import communityIcon from '~/assets/images/icons/communities.png'
import forumIcon from '~/assets/images/icons/forum.png'
import landingVideoThumbnail from '~/assets/images/landing_video_thumbnail.png'
import VideoPlayer from '~/components/common/molecules/media/VideoPlayer'
import Button from '~/components/workarounds/Button'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import { FontSize } from '~/constants/common/font'
import {
  getFontByLanguage,
  getMediumFontByLanguage,
} from '~/utils/common/themeConfig'
import { RootStackNavigationProp } from '~/utils/navigation'

type Props = {
  isPC?: boolean
}

const videoUrl =
  'https://japan-alumni.world/videos/landing_page_video.mp4'

const LandingBottomSection: React.FC<Props> = ({ isPC }) => {
  const { t } = useTranslation()
  const { navigate } = useNavigation<RootStackNavigationProp>()

  const navigateToJobs = () => {
    if (isPC) {
      return navigate('careerMain', {
        screen: 'jobs',
      })
    }
    navigate('careerMain', {
      screen: 'mobiletab',
      params: {
        screen: 'jobs',
      },
    })
  }

  const highlights = [
    {
      icon: careerIcon,
      title: 'appService.career.title',
      subtitle: 'appService.career.subtitle',
      buttonText: 'landing.viewJobs',
      buttonPress: navigateToJobs,
    },
    {
      icon: forumIcon,
      title: 'appService.forum.title',
      subtitle: 'appService.forum.subtitle',
      buttonText: 'landing.goToForum',
      buttonPress: () => navigate('forumMain'),
    },
    {
      icon: communityIcon,
      title: 'appService.community.title',
      subtitle: 'appService.community.subtitle',
    },
  ]

  return (
    <View style={styles.container} testID="landing-bottom-section">
      <View
        style={
          Platform.OS === 'ios' || Platform.OS === 'android'
            ? styles.videoWrapperMobile
            : styles.videoWrapper
        }
      >
        <View style={styles.video}>
          <VideoPlayer
            uri={videoUrl}
            stretch="horizontal"
            thumbnail={landingVideoThumbnail}
          />
        </View>
      </View>
      <View
        style={isPC ? styles.videoBackground : styles.videoBackgroundMobile}
      />
      <View
        testID="highlight-section"
        style={isPC ? styles.highlightSection : styles.highlightSectionMobile}
      >
        {highlights.map((item, index) => (
          <View
            style={[
              styles.highlightItem,
              isPC ? styles.pc : index !== highlights?.length - 1 && styles.mb,
            ]}
            key={index}
          >
            <View>
              <Image source={item.icon} style={styles.highlightImage} />
              <Text style={styles.highlightHeading}>{t(item.title)}</Text>
              <Text style={styles.paragraph}>{t(item.subtitle)}</Text>
            </View>
            {item.buttonText && (
              <Button
                title={t(item.buttonText)}
                onPress={item.buttonPress}
                buttonStyle={styles.highlightButton}
                titleStyle={styles.buttonTitle}
              />
            )}
          </View>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonTitle: {
    fontSize: FontSize.REMARKABLE,
  },
  container: {
    alignItems: 'center',
    backgroundColor: color.white,
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  highlightButton: {
    paddingHorizontal: 28,
  },
  highlightHeading: {
    fontFamily: getMediumFontByLanguage(i18n.language),
    fontSize: 27,
    marginBottom: 10,
  },
  highlightImage: {
    alignSelf: 'flex-start',
    height: 80,
    marginLeft: -10,
    minWidth: 77,
    resizeMode: 'contain',
  },
  highlightItem: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  highlightSection: {
    flexDirection: 'row',
    marginVertical: 64,
    maxWidth: 900,
  },
  highlightSectionMobile: {
    flexDirection: 'column',
    marginVertical: 64,
  },
  mb: {
    marginBottom: 40,
  },
  paragraph: {
    fontFamily: getFontByLanguage(i18n.language),
    fontSize: FontSize.REMARKABLE,
    lineHeight: 23,
  },
  pc: {
    flex: 1,
  },
  video: {
    borderRadius: 20,
    overflow: 'hidden',
  },
  videoBackground: {
    backgroundColor: color.primary,
    marginTop: -240,
    paddingBottom: 320,
    position: 'relative',
    width: '100%',
  },
  videoBackgroundMobile: {
    backgroundColor: color.primary,
    marginTop: -100,
    paddingBottom: 135,
    position: 'relative',
    width: '100%',
  },
  videoWrapper: {
    maxWidth: 900,
    paddingHorizontal: 50,
    width: Dimensions.get('window').width,
    zIndex: 1,
  },
  videoWrapperMobile: {
    maxWidth: 900,
    paddingHorizontal: 50,
    height: 200,
    width: Dimensions.get('window').width,
    zIndex: 1,
  },
})

export default LandingBottomSection
